import React from 'react'
import Slider from 'infinite-react-carousel';

export default function Carousel() {
  return (
    < Slider
      dots={false}
      autoplay={true}
      arrows={false}
      autoplaySpeed={5000}
      duration={300}
      shift={0}
    >
      <div>
        <img src="../img_slide/banner1.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      <div>
        <img src="../img_slide/2-product.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
       <div>
        <img src="../img_slide/3-product.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      <div>
        <img src="../img_slide/whisky yordxam2.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      {/*<div>
        <img src="../4.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div> */}
    </Slider>
  );
}