import { Box, Typography, Button, TextField } from '@mui/material/';

export default function Login(props) {

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height="100vh"
    >
      <Typography
        variant="h2"
        gutterBottom
        fontSize={{xs: 30, sm: 50, md: 60, lg: 70}}
        //className={classes.typography}
      >
        {props.title}
      </Typography>
      <Box m={1} width={{ lg: 400, md: 350, sm: 280, xs: 180 }} >
        <TextField
          id="outlined-basic"
          label={props.label1}
          variant="outlined"
          onChange={props.handleChange1}
          fullWidth
          InputProps={{
            sx: {fontSize: {xs: 14, sm: 18, md: 20, lg: 22}}
          }}
          InputLabelProps={{
            sx: {fontSize: {xs: 14, sm: 18, md: 20, lg: 22}}
          }}
        />
      </Box>
      <Box m={2} width={{ lg: 400, md: 350, sm: 280, xs: 180 }} >
        <TextField
          id="outlined-basic"
          label={props.label2}
          variant="outlined"
          onChange={props.handleChange2}
          fullWidth
          type='password'
          inputProps={{
            sx: {fontSize: {xs: 14, sm: 18, md: 20, lg: 22}}
          }}
          InputLabelProps={{
            sx: {fontSize: {xs: 14, sm: 18, md: 20, lg: 22}}
          }}
        />
      </Box>

      <Box width={{ lg: 400, md: 350, sm: 280, xs: 180 }} >
        <Button
          variant="contained"
          onClick={props.handleClick}
          fullWidth
          sx={{fontSize: { xs: 14, sm: 18, md: 20, lg: 22 } }}
        >
          {props.button}
        </Button>
      </Box>

    </Box>
  )
}
