import React from 'react'
import { Grid, Box, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import Cards from '../../../modules/Cards/Cards'
// import CardsEvents from '../../../modules/Cards/CardsEvents';
// import CardsEvents2 from '../../../modules/Cards/CardsEvents2';
// import CardsEvents3 from '../../../modules/Cards/CardsEvents3';
import Home1 from '../../../images/home/1yordxam_mission-vision.png'
import Home2 from '../../../images/home/2yordxam_map.jpg'
import Home3 from '../../../images/home/3yordxam_farmer.jpg'
import Home4 from '../../../images/home/4yordxam_khaokainoi.jpg'


const useStyles = makeStyles((theme) => ({
  text1: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: 30
  },
  text2: {
    color: theme.palette.secondary.main,
  },
  topic: {
    color: theme.palette.secondary.dark,
  },
  underLine: {
    width: '22vw',
    backgroundColor: theme.palette.primary.main,
  },
  underLineEn: {
    width: '15.5vw',
    backgroundColor: theme.palette.primary.main,
  },
  underLine2: {
    backgroundColor: theme.palette.primary.main,
  },
  outlineImg: {
    //backgroundColor: theme.palette.secondary.light,
    padding: '2% 5%',
    //backgroundColor: 'pink',
  }
}));

export default function Homepage(props) {
  const classes = useStyles();
  // const ref = useRef(null)


  // React.useEffect(() => {
  //   ref.current.scrollIntoView({behavior: 'smooth', })
  // });

  const cards = [
    { id: '1', img: [Home1, Home2, Home3, Home4]}
  ];

  return (
    <div style={{ height: '' }}>
      <Box style={{ padding: '5%' }}>
        <>
          {/* <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {props.i18n.language === "la" ?
                <>
                  <Typography component={'div'} style={{ fontSize: '2.5vw' }} className={classes.topic}>{props.t("title.4")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></Typography>
                  <Divider component={'div'} sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                </>
                :
                <>
                  <Typography component={'div'} style={{ fontSize: '2.5vw' }} className={classes.topic}>{props.t("title.4")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLineEn} /></Typography>
                  <Divider component={'div'} sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                </>
              }
              <br />
            </div>
          </div> */}
          
          <Grid container  >
          {cards.map((row) => (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {row.img.map((img, index) => (
              <div key={index} style={{ width: '100%' }}>
                <CardMedia
                  component="img"
                  height="auto"
                  width="100%"
                  src={img}
                  alt="Paella dish"
                  className={classes.outlineImg}
                />
              </div>
              ))}
            </Grid>
          ))}
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardsEvents2 i18n={props.i18n} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardsEvents3 i18n={props.i18n} />
            </Grid> */}
          </Grid>
        </>



      </Box> {/**/}

    </div>
  )
}
