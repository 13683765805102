import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import axios from 'axios';
import Loading from '../../modules/Loading/Loading';
import { useTranslation } from 'react-i18next';

//routes
import Homepage from './Homepage/Homepage';
import LoginPage from './LoginPage/LoginPage';
import CreateProductPage from './Product/CreateProductPage';
import EditProductPage from './Product/EditProductPage';
//import ProductManagement from './ProductManagement/ProductManagement';

export default function Index() {
  const [isLoggedIn, setisLoggedIn] = useState(false)
  const [accessToken, setaccessToken] = useState("")
  const [userInfo, setuserInfo] = useState({})
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => {
      axios({
        url: window.$api + "/refresh",
        method: "POST",
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "content-Type": "application/json;charset-UTF-8"
        },
      }).then(res => {
        //console.log(res.data)
        if (res.data.status === 1) {
          setisLoggedIn(true)
          setaccessToken(res.data.jid)
          setuserInfo(res.data.userInfo)
          setLoading(false)
        } else {
          setisLoggedIn(false)
          setLoading(false)
        }
      })
    }, 2000)
    return () => clearTimeout(timer);
  }, [])

  if (!loading) {
    return (
      <div>
        <Routes>
          <Route index path="*" element={<Navigate to="/admin" replace />} />
          {/* <Route path="/product-management" element={<ProductManagement isLoggedIn={isLoggedIn} />} /> */}
          <Route path="/edit-product" element={<EditProductPage isLoggedIn={isLoggedIn} />} t={t}/>
          <Route path="/create-product" element={<CreateProductPage isLoggedIn={isLoggedIn} t={t}/>} />
          <Route path="/login" element={<LoginPage isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} setaccessToken={setaccessToken} t={t}/>} />
          <Route path="/" element={<Homepage userInfo={userInfo} accessToken={accessToken} isLoggedIn={isLoggedIn} t={t}/>} />
        </Routes>
      </div>
    )
  } else {
    return (
      <div>
        <Loading />
      </div>
    )
  }
}
