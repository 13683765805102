import React, { useState } from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import SiteBar from './Appbar/Appbar';
import HomePage from './Home/HomePage'
import Products from './Products/ProductPage'
import AboutUs from './AboutUs/AboutusPage'
import ContactUs from './ContactUs/ContactPage'
import Footer from './Footer/Footer'
import { useTranslation } from 'react-i18next';
import ProductDetails from '../../modules/ProductCard/ProductDetails';
import ProductCart from '../../modules/ProductCart/ProductCart';
import countapi from 'countapi-js';
import axios from 'axios';
//import ls from 'localstorage-slim';

export default function Index() {
  const { t, i18n } = useTranslation()
  const [view, setView] = React.useState()
  const [cartList, setcartList] = useState([])

  React.useEffect(() => {
    var cart = JSON.parse(localStorage.getItem("cart"))
    //ls.get('cart', { decrypt: true });
    
    // if(ls.get('cart')){
    //   var cart = ls.get('cart')
    // }
    //console.log(cart)

    // ls.config.encrypter = (data, secret) => {
    //   return true;
    // };

    if (cart) {
      axios({
        url: window.$api + "/validateCartData",
        method: "POST",
        headers: {
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
        },
        data: {
          cart: cart
        }
      }).then(res => {

        //console.log(res.data)
        // if(res.data){
        localStorage.setItem("cart", JSON.stringify(res.data))
          //setcartList(res.data)
        // }else{
        //   localStorage.setItem("cart", '[]')
        //   setcartList([])
        // }
        setcartList(res.data)
      })
      //localStorage.setItem("cart", JSON.stringify(res.data))
      
    } else {
      localStorage.setItem("cart", "[]")
    }

    const timer = setTimeout(() => {
      countapi.visits().then((result) => {
        setView(result.value);
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [])



  return (
    <div >

      <div>

        <SiteBar t={t} i18n={i18n} cartList={cartList} />

      </div>
      <Routes>
        <Route index path="*" element={<Navigate to="/" replace />} />
        <Route path="/cart" element={<ProductCart t={t} i18n={i18n} cartList={cartList} setcartList={setcartList} />} />
        <Route path="/product/:id" element={<ProductDetails t={t} setcartList={setcartList} />} />
        <Route path="/contactus" element={<ContactUs t={t} i18n={i18n} />} />
        <Route path="/aboutus" element={<AboutUs t={t} i18n={i18n} />} />
        <Route path="/products" element={<Products t={t} i18n={i18n} />} />
        <Route path="/" element={<HomePage t={t} i18n={i18n} />} />
      </Routes>
      <div>
        <Footer t={t} i18n={i18n} view={view} />

      </div>
    </div>
  )
}