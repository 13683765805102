import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { Container, Grid, Typography, Divider, Box } from '@mui/material'
import Carousel from '../Carousel/ImageCarousel'
import { makeStyles } from '@mui/styles';
import axios from 'axios'
import DialogInputInfo from '../Popup/DialogInputInfo.js'
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 8
    },
    root1: {
        marginTop: "2%",
        marginBottom: "3%",
        width: "100%",
        margin: "auto"
    },
    button: {
        backgroundColor: theme.palette.primary.light,
        padding: '2%',
        borderRadius: 3,
        //color: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            transition: '1s',
        }
    },
    topic: {
        color: theme.palette.secondary.dark,
    },
    underLine: {
        width: '19.5vw',
        backgroundColor: theme.palette.primary.main,
    },
    underLine2: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function ProductDetails(props) {
    const classes = useStyles();
    const params = useParams();
    const [productInfo, setproductInfo] = useState({})
    const [open, setOpen] = useState(false)
    const [purchaseInfo, setpurchaseInfo] = React.useState({})

    const openInputInfo = () => {
        setOpen(true)
    }

    const closeInputInfo = () => {
        setOpen(false)
    }

    const handlePayment = () => {
        var cart = []
        var data = {
            id: productInfo.id,
            product_name: productInfo.product_name,
            price: productInfo.price,
            promo_price: productInfo.promo_price || 0,
            qty: purchaseInfo.qty
        }
        cart.push(data)
        axios({
            url: window.$api + "/order",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                orderInfo: purchaseInfo,
                cart: cart,
                total: productInfo.promo_price > 0 ? productInfo.promo_price * purchaseInfo.qty : productInfo.price * purchaseInfo.qty
            }
        }).then(res => {
            if(res.data === 1){
                setpurchaseInfo({})
                setOpen(false)
                alert('success')
            }else{
                alert('fail')
                setOpen(false)
            }
        })
    }

    useEffect(() => {
        axios({
            url: window.$api + "/getProductById",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                id: params.id
            }
        }).then(res => {
            if (res.data.productInfo) {
                var data = res.data.productInfo
                var arr = []
                arr.push(res.data.productInfo.img_id)

                res.data.imgListInfo.forEach(el => {
                    arr.push(el.img_id)
                });
                data.img_id = arr
                setproductInfo(data)
            }
        })
    }, [params.id])

    const addToCart = () => {
        var cart = JSON.parse(localStorage.getItem("cart") || '[]')

        const check = cart.some(el => Number(el.id) === Number(params.id))

        if (!check) {
            var data = {
                id: params.id,
                product_name: productInfo.product_name,
                price: productInfo.price,
                img_id: productInfo.img_id[0],
                promo_price: productInfo.promo_price,
                qty: 1
            }

            cart.push(data)
            localStorage.setItem("cart", JSON.stringify(cart))
            props.setcartList(cart)
        } else {
            alert("Already exist")
        }
    }

    const onChangeInputField = (e) => {
        setpurchaseInfo({
            ...purchaseInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectMenu = (id, menu) => {
        setpurchaseInfo({
            ...purchaseInfo,
            express: menu
        })
    }

    return (
        <div >
            <Container maxWidth='false' style={{ padding: '5%' }}>
                <div className={classes.root1}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <Typography component={'div'} style={{ fontSize: '2.5vw' }} className={classes.topic}>{props.t("title.6")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></Typography>
                            <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                            <br />
                        </div>
                    </div>
                    <br /><br />
                    <Grid container className={classes.root}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: '1%', paddingLeft: '5%' }} >
                            {productInfo.img_id &&
                                <Carousel img={productInfo.img_id} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: '1%' }} >
                            <Typography variant='h2'>{productInfo.product_name || ""}</Typography>
                            <br />
                            {productInfo.promo_price > 0 ?
                                <Box display='flex' >
                                    <NumericFormat value={productInfo.price + " ກີບ" || ""} displayType={'text'} suffix={' ' + props.t('mn.1')} thousandSeparator={true} renderText={value => <div><Typography variant='h2' marginLeft='10px' color="primary"><del>{value}</del></Typography></div>} />
                                    &nbsp;
                                    <NumericFormat value={productInfo.promo_price + " ກີບ" || ""} displayType={'text'} suffix={' ' + props.t('mn.1')} thousandSeparator={true} renderText={value => <div><Typography variant='h2' color="error">{value}</Typography></div>} />
                                </Box>
                                :
                                <>
                                    <NumericFormat value={productInfo.price + " ກີບ" || ""} displayType={'text'} suffix={' ' + props.t('mn.1')} thousandSeparator={true} renderText={value => <div><Typography variant='h2' color="error">{value}</Typography></div>} />
                                </>
                            }

                            <br />
                            <Typography>{productInfo.description || ""}</Typography>
                            <br />
                            {productInfo.id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className={classes.button} onClick={addToCart}>
                                        <Typography>{props.t('cart.1')}</Typography>
                                    </div>
                                    &nbsp;
                                    <div className={classes.button} onClick={openInputInfo}>
                                        <Typography>{props.t('pay.1')}</Typography>
                                    </div>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <DialogInputInfo 
            t={props.t} 
            open={open} 
            value={purchaseInfo}
            onChange={onChangeInputField}
            handleSelectMenu={handleSelectMenu}
            closeInputInfo={closeInputInfo} 
            handlePayment={handlePayment}
            buyNow={true}
            />
            <br /><br /><br /><br />
        </div>
    )
}
