import React, { useState } from 'react'
import { Box } from '@mui/material/';
import axios from 'axios';
import Login from '../../../modules/Auth/Login';
import { Navigate } from "react-router-dom";

export default function LoginPage(props) {
  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")

  const handleLogin = () => {
    axios({
      url: window.$api + "/login",
      method: "POST",
      withCredentials: true,
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        username: username,
        password: password
      }
    }).then(res => {
      if(res.data.status === 1){
        props.setaccessToken(res.data.jid)
        props.setisLoggedIn(true)
      }else{
        alert("ລ໋ອກອິນບໍ່ສຳເລັດ")
      }
      
    })
  }

  // const sendmail = () => {
  //   axios({
  //     url: window.$api + "/contactus",
  //     method: "POST",
  //     withCredentials: true,
  //     headers: {
  //       "Accept": "application/json",
  //       "content-Type": "application/json;charset-UTF-8"
  //     },
  //     data: {
  //       subject: "Products",
  //       description: "rice 2 packs",
  //       name: "jack"
  //     }
  //   }).then(res => {
  //     console.log(res.data)
  //   })
  // }

  const handleChangeUsername = (e) => {
    setusername(e.target.value)
  }

  const handleChangePassword = (e) => {
    setpassword(e.target.value)
  }

  if (!props.isLoggedIn) {
    return (
      <Box >
        <Login
          title="ກະລຸນາເຂົ້າລະບົບ"
          label1="ຊື່ຜູ້ໃຊ້"
          label2="ລະຫັດຜ່ານ"
          handleChange1={handleChangeUsername}
          handleChange2={handleChangePassword}
          button="ເຂົ້າລະບົບ"
          handleClick={handleLogin}
        />
      </Box>
      
    )
  } else {
    return (
      <Box>
        <Navigate to='/admin' replace />
      </Box>
    )
  }
}
