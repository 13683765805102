import { Box, Typography } from '@mui/material/';
import { useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AuthenWarning from '../../../modules/Popup/AuthenWarning';
import React from 'react';

const cardStyle = {
  "&:hover": {
    backgroundColor: "#F5F5F5"
  },
  padding: 10,
  cursor: "pointer"
}

export default function HomePage(props) {
  const navigate = useNavigate();
  //const [open, setopen] = React.useState(true)

  const handleClick = () => {
    navigate("/admin/login")
  }

  if (props.isLoggedIn) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100vh"
        width="100vw"
        sx={{
          backgroundColor: "#74EBD5",
          backgroundImage: "linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%)"
        }}
      >
        <Box  >
          <Typography align='center' fontSize={{ xs: "6vh", sm: 60, md: 70, lg: 50 }}>ຈັດການສິນຄ້າ</Typography>
        </Box>
        <Box
        display={{ xs: "flex" }}
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: "column",lg: "row"}}
        >
          <Box
            sx={cardStyle}
            m={{ xs: 2, sm: 5 }}
            boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            borderRadius={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection={{ xs: "column" }}
            width={{ xs: "65vw", sm: "50vw", md: "45vw", lg: "20vw"}}
            height={{xs: "20vh", sm: "25vh"}}
            backgroundColor="white"
            onClick={()=> navigate("/admin/create-product")}
          >
            <AddIcon sx={{fontSize: {xs: 30, sm: 40, md: 50, lg: 40}}} />
            <Typography fontSize={{ xs: 16, sm: 25, md: 30, lg: 18 }}>ສ້າງສິນຄ້າ</Typography>
          </Box>
          <Box
            sx={cardStyle}
            m={{ xs: 2, sm: 5 }}
            boxShadow="0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            borderRadius={3}
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection={{ xs: "column" }}
            width={{ xs: "65vw", sm: "50vw", md: "45vw", lg: "20vw" }}
            height={{xs: "20vh", sm: "25vh"}}
            backgroundColor="white"
            onClick={()=> navigate("/admin/edit-product")}
          >
            <EditIcon sx={{fontSize: {xs: 30, sm: 40, md: 50, lg: 40}}} />
            <Typography fontSize={{ xs: 16, sm: 25, md: 30, lg: 18 }}>ແກ້ໄຂສິນຄ້າ</Typography>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <AuthenWarning 
        t={props.t}
        content="ໜ້ານີ້ສຳລັບແອັດມິນ"
        title="ແຈ້ງເຕືອນ"
        handleClick={handleClick}
        open={true}/>
      </Box>
    )
  }
}