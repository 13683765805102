import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, TextField, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        //textAlign: 'center',
        color: theme.palette.background.default,
        justifyContent: 'center'
    },
    bgcolor: {
        backgroundColor: theme.palette.secondary.light,
        padding: '1%',
        display: "flex",
        flexDirection: "column"
    },
    bgTitleContent: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.light,
        //backgroundColor: 'red',
    },
    content: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default function DialogInputInfo(props) {
    const classes = useStyles();

    const express = [
        { id: '1', name: 'YordXam (ສະເພາະນະຄອນຫຼວງ)' },
        { id: '2', name: 'Mixay' },
        { id: '3', name: 'Anousit' },
        { id: '4', name: 'Captain' },
        { id: '5', name: 'Hal Logistic ຮຸ່ງອາລຸນ' },
    ]

    return (
        <div>
            <Dialog onClose={props.closeInputInfo} open={props.open} aria-labelledby="form-dialog-title" scroll="body" maxWidth='sm' fullWidth sx={{ padding: "4%" }}>
                <DialogTitle>
                    <div style={{ textAlign: 'center' }}>
                        {props.t('title.12')}
                    </div>
                </DialogTitle>
                <div style={{ padding: '2%' }}>
                    <DialogContent className={classes.bgcolor}>
                        <TextField
                            name='name'
                            label={props.t('info.4')}
                            variant='outlined'
                            value={props.value.name || ""}
                            onChange={props.onChange}
                        />
                        <br />
                        <TextField
                            name='phone'
                            id="outlined-basic"
                            label={props.t('info.7')}
                            variant="outlined"
                            value={props.value.phone || ""}
                            onChange={props.onChange}
                        />
                        <br />
                        <TextField
                            name='address'
                            label="ທີ່ຢູ່"
                            variant='outlined'
                            value={props.value.address || ""}
                            onChange={props.onChange}
                        />
                        <br />
                        <TextField
                            name='express'
                            label="ຊື່ຂົນສົ່ງ"
                            variant='outlined'
                            select
                            value={props.value.express || ""}
                        >
                            {express.map((row) => (
                                <MenuItem key={row.id} value={row.name || ""} onClick={() => props.handleSelectMenu(row.id, row.name)}>
                                    {row.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <br />
                        {props.buyNow &&
                            <TextField
                                name='qty'
                                label="ຈຳນວນສິນຄ້າ"
                                variant='outlined'
                                value={props.value.qty || ""}
                                onChange={props.onChange}
                            />
                        }
                    </DialogContent>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5%' }}>
                    <Button variant="contained" onClick={props.handlePayment} style={{ textTransform: 'none' }} >
                        {props.t('submit.1')}
                    </Button>&nbsp;&nbsp;
                    <Button variant="contained" onClick={props.closeInputInfo} style={{ textTransform: 'none' }} >
                        {props.t('cancel.1')}
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}
