import React from 'react'

export default function GoogleMap() {
  //var map = new google.maps.Map(document.getElementById("googleMap"),mapProp);
  return (
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3797.189041333466!2d102.6412!3d17.876611999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMTfCsDUyJzM1LjgiTiAxMDLCsDM4JzI4LjMiRQ!5e0!3m2!1sth!2sla!4v1666273386074!5m2!1sth!2sla"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="myFrame"
        >

      </iframe>
  )
}




