import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import { Card, Typography } from '@mui/material';


const useStyles = makeStyles((theme) => ({

    text: {
        color: theme.palette.primary.main,
        fontSize: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem',
        },
        textAlign: 'center'
    },
    boxCount: {
        color: theme.palette.primary.main,
        //backgroundColor: '#333',
        textShadow: '0 1px 0 white',
        borderRadius: '5%',
        //position: 'absolute',
        backgroundColor: theme.palette.background.default,
        padding: '0.1% 1.2%',
        display: 'flex'
    }
}))

export default function Visitor(props) {
    const classes = useStyles();

    const [count, setCount] = useState(10)


    useEffect(() => {
        var result = 0;
        var i = 0;
        for (i; i < props.view; i++) {
            result++;
        }
        setCount(result)
    }, [props.view]);

    //let str = "infinitbility";
    //console.log(str.length)

    // let couter = 101;
    // let counts = 100;
    // let i = 0
    // for ( i = 0; i < counts; i++) {
    //     console.log(counts[i]);
    // }
    return (
        <Card className={classes.boxCount}>

            <Typography variant='h6' className={classes.text} style={{ fontWeight: '300' }}>
                {count}
            </Typography>
        </Card>
    )
}
