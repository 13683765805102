import React from 'react'
import { IconButton } from '@mui/material'
import PhotoCamera from '@mui/icons-material/PhotoCamera';

export default function ImageUpload(props) {
    return (
        <div>
            <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={props.onChangeImg} />
                <PhotoCamera sx={{ fontSize: { xs: 30, sm: 50 } }} />
            </IconButton>
        </div>
    )
}

//props.setimg(URL.createObjectURL(e.target.files[0])