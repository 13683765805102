import { Box, Button, Typography, TextField } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios';
import TextfieldModule from '../../../modules/Textfield/TextfieldModule';
import ImageUpload from '../../../modules/Upload/ImageUpload';
import { makeStyles } from '@mui/styles';
import Backdrop from '../../../modules/Backdrop/Backdrop';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AuthenWarning from '../../../modules/Popup/AuthenWarning'

const useStyles = makeStyles((theme) => ({
    img: {
        padding: theme.spacing(1),
        backgroundColor: "white",
        borderRadius: "10%",
        margin: 5,
        [theme.breakpoints.down('sm')]: {
            width: "50%",

        },
        [theme.breakpoints.up('sm')]: {
            width: "30%",
        },
        [theme.breakpoints.up('md')]: {
            width: "30%",
        },
        [theme.breakpoints.up('lg')]: {
            width: "60%",
        },
    },
    subimg: {
        //padding: theme.spacing(1),
        backgroundColor: "white",
        borderRadius: "10%",
        margin: "0px 5px 5px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 50
        },
        [theme.breakpoints.up('sm')]: {
            width: 60,
            height: 70
        },
    },
}));

export default function CreateProductPage(props) {
    const classes = useStyles();
    const navigate = useNavigate()
    const [productName, setproductName] = useState("")
    const [price, setprice] = useState()
    const [description, setdescription] = useState("")
    const [img, setimg] = useState("")
    const [openBackdrop, setopenBackdrop] = useState(false)
    const [imgList, setimgList] = useState([])

    const handleClick = () => {
        navigate("/admin/login")
      }

    const textfields = [
        { id: 1, label: "ຊື່ສິນຄ້າ", value: productName, setValue: setproductName },
        { id: 2, label: "ລາຄາ", value: price, setValue: setprice },
        //{ id: 3, label: "ຄຳອະທິບາຍ", value: description, setValue: setdescription },
    ]

    const onChangeImg = (e) => {
        if (e.target.files[0]) {
            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result; // <--- data: base64

                //var newImage = document.createElement('img');
                //newImage.src = srcData;

                //document.getElementById("imgTest").innerHTML = newImage.outerHTML;
                //alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
                //console.log(srcData);
                setimg(srcData)
            }
            fileReader.readAsDataURL(e.target.files[0]);
        }
    }

    const onChangeImgList = (e) => {
        if (imgList.length < 3) {
            if (e.target.files[0]) {
                var fileReader = new FileReader();
                fileReader.onload = function (fileLoadedEvent) {
                    var srcData = fileLoadedEvent.target.result; // <--- data: base64
                    var newArr = [...imgList]
                    var data = {
                        img: srcData
                    }
                    newArr.push(data)
                    setimgList(newArr)
                }
                fileReader.readAsDataURL(e.target.files[0]);
                //document.getElementById('imglist').value = null
            }


        } else {
            alert("ສາມາດເພີ່ມໄດ້ສູງສຸດ 3 ຮູບ")
        }

    }

    const onEditImgList = (e, index) => {
        if (e.target.files[0]) {
            var fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result; // <--- data: base64
                var newArr = [...imgList]
                newArr[index].img = srcData
                setimgList(newArr)
            }
            fileReader.readAsDataURL(e.target.files[0]);
        }
    }

    const handleCreateProduct = () => {
        if (productName && description && price) {
            if (!isNaN(price)) {
                if (img) {
                    setopenBackdrop(true)
                    axios({
                        url: window.$api + "/createProduct",
                        method: "POST",
                        headers: {
                            "Accept": "application/json",
                            "content-Type": "application/json;charset-UTF-8"
                        },
                        data: {
                            product_name: productName || null,
                            price: price || null,
                            description: description || null,
                            img_id: img || null,
                            imgList: imgList || null
                        }
                    }).then(res => {
                        //alert(res.data)
                        setopenBackdrop(false)
                        navigate('/admin/edit-product')
                    })
                } else {
                    alert('ກະລຸນາອັບໂຫຼດຮູບສິນຄ້າ')
                }
            } else {
                alert('ກະລຸນາປ້ອນລາຄາເປັນໂຕເລກ')
            }
        } else {
            alert('ກະລຸນາປ້ອນຂໍ້ມູນ')
        }
    }

    if (props.isLoggedIn) {
        return (
            <Box height="100vh" display={{ xs: "flex" }} flexDirection={{ xs: "column", lg: "row" }}>
                <Box
                    //height={{ xs: "50vh", lg: "100vh" }}
                    width={{ xs: "100vw", lg: "40vw" }}
                    sx={{ backgroundColor: "#F5F5F5" }}
                    display={{ xs: "flex" }}
                    flexDirection={{ xs: "column" }}
                    justifyContent={{ xs: "center" }}
                    alignItems={{ xs: "center" }}
                >
                    {!img ?
                        <Box
                            display={{ xs: "flex" }}
                            flexDirection={{ xs: "column" }}
                            justifyContent={{ xs: "center" }}
                            alignItems={{ xs: "center" }}
                        >
                            <Typography fontSize={{ xs: 20, sm: 25, md: 30, lg: 22 }}>ອັບໂຫຼດຮູບສິນຄ້າ</Typography>
                            <ImageUpload img={img} setimg={setimg} onChangeImg={onChangeImg} />
                        </Box>

                        :
                        <Box display="flex" justifyContent={{ xs: "center" }} alignItems={{ xs: "center" }} flexDirection={{ xs: "column" }}>
                            <Box display="flex" justifyContent={{ xs: "center" }} sx={{ cursor: "pointer" }} >
                                <Button color="primary" aria-label="upload picture" component="label">
                                    <img id="img" src={img} alt='' className={classes.img} />

                                    <input id="imglist" hidden accept="image/*" type="file" onChange={onChangeImgList} />
                                </Button>
                            </Box>

                            {img &&
                                <Box display="flex" flexDirection={{ xs: "row" }} justifyContent='center'>

                                    <Box
                                        backgroundColor="white"
                                        width={{ xs: 40, sm: 50 }}
                                        height={{ xs: 50, sm: 70 }}
                                        display="flex"
                                        justifyContent='center'
                                        alignItems="center"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Button color="primary" aria-label="upload picture" component="label">
                                            <AddIcon />
                                            <input hidden accept="image/*" type="file" onChange={onChangeImgList} />
                                        </Button>

                                    </Box>
                                    {imgList.map((row, index) =>
                                        <Button key={index} color="primary" aria-label="upload picture" component="label">
                                            <img id="img" src={row.img} alt='' className={classes.subimg} />
                                            <input hidden accept="image/*" type="file" onChange={(e) => onEditImgList(e, index)} />
                                        </Button>

                                    )}

                                    {/* <img id="img" src={img} alt='' className={classes.subimg} />
                                    <img id="img" src={img} alt='' className={classes.subimg} />
                                    <img id="img" src={img} alt='' className={classes.subimg} /> */}

                                </Box>
                            }

                        </Box>
                    }
                </Box>
                <Box
                    //height={{ xs: "70vh", lg: "100vh" }}
                    width={{ xs: "100vw", lg: "60vw" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Typography fontSize={{ xs: 20, sm: 25, md: 30, lg: 26 }}>ລາຍລະອຽດສິນຄ້າ</Typography>
                    {textfields.map(row =>
                        <Box key={row.id}>
                            <TextfieldModule
                                label={row.label}
                                value={row.value}
                                setValue={row.setValue}
                                width={{ xs: "60vw", sm: "60vw", md: "60vw", lg: "40vw" }}
                                inputFrontSize={{ xs: 14, sm: 16, md: 18 }}
                                labelFrontSize={{ xs: 14, sm: 16, md: 18 }}
                            />
                        </Box>
                    )}

                    <Box
                        width={{ xs: "60vw", sm: "60vw", md: "60vw", lg: "40vw" }}
                        m="10px auto"
                    >
                        <Typography fontSize={{ xs: 14, sm: 16, md: 18 }}>ຄຳອະທິບາຍ</Typography>
                        <TextField
                            id="des"
                            type="text"
                            label="ຄຳອະທິບາຍ"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={12}
                            InputProps={{
                                sx: { fontSize: { xs: 14, sm: 16, md: 18 } }
                            }}
                            InputLabelProps={{
                                sx: { fontSize: { xs: 14, sm: 16, md: 18 } }
                            }}
                            onChange={(e) => setdescription(e.target.value)}
                        />
                    </Box>


                    {/* <Box
                 width={{ xs: "60vw", sm: "50vw", md: "40vw", lg: "60vw" }}
                 height={{xs: "40vh"}}
                 maxHeight="40vh"
                 
                >
                    <Typography fontSize={{ xs: 14, sm: 16, md: 18 }} align="center">ຄຳອະທິບາຍ</Typography>
                <Editor
                    //editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                //onEditorStateChange={this.onEditorStateChange}
                />
                </Box> */}
                    <Box width={{ xs: "60vw", sm: "60vw", md: "60vw", lg: "40vw" }} margin="10px auto">
                        <Button onClick={handleCreateProduct} variant='contained' fullWidth sx={{ fontSize: { xs: 14, sm: 16, md: 18 } }}>
                            ສ້າງສິນຄ້າ
                        </Button>
                    </Box>
                </Box>
                <Backdrop open={openBackdrop} />
            </Box>
        )
    } else {
        return (
            <Box>
                <AuthenWarning
                    t={props.t}
                    content="ໜ້ານີ້ສຳລັບແອັດມິນ"
                    title="ແຈ້ງເຕືອນ"
                    handleClick={handleClick}
                    open={true} 
                />
            </Box>
        )
    }
}
