import React from 'react'
import { Box, TextField } from '@mui/material'

export default function Textfield(props) {
    return (
        <Box width={props.width} m="10px auto">
            <TextField
                variant='outlined'
                label={props.label}
                value={props.value || ""}
                onChange={(e)=> props.setValue(e.target.value)}
                InputProps={{
                    sx: { fontSize: props.inputFrontSize }
                }}
                InputLabelProps={{
                    sx: { fontSize: props.labelFrontSize }
                }}
                fullWidth
            />
        </Box>
    )
}
