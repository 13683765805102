import React from 'react'
import ProductCard from '../ProductCard/ProductCard'
import { Box } from '@mui/material';

export default function CardListGird(props) {
    return (
        <Box sx={{ width: '100%', backgroundColor: '', display: 'flex', justifyContent: 'center',}} >
            <ProductCard card={props.card} t={props.t} cardLink={props.cardLink} />
        </Box>
    )
}
