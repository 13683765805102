import { CardMedia } from '@mui/material';
import React from 'react'
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({

    topic: {
        color: theme.palette.primary.main,
    },
    outlineImg: {
        //backgroundColor: theme.palette.secondary.light,
        //padding: '1%',
    }
}))

export default function ImageCarousel(props) {
    const classes = useStyles();

    return (
        <div style={{ height: 'auto', paddingRight: '10%' }}>
            <Carousel
                style={{ width: '100%', backgroundColor: '#0000' }}
                hasLeftButton={false}
                hasRightButton={false}
                hasMediaButton={false}
                hasIndexBoard={false}
                hasSizeButton={false}
                canAutoPlay={false}
                objectFit='contain'
            >

                {
                    props.img.map((img, index) => (
                        <div style={{ width: '100%', minWidth: '100%', padding: '0.5%' }} key={index}>
                            {img &&
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    width="50%"
                                    src={window.$api + '/getCourseImg/' + img}
                                    alt="Paella dish"
                                    className={classes.outlineImg}
                                />
                            }
                        </div>
                    ))
                }
            </Carousel>
            <br />
        </div>
    )
}
