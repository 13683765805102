import { Box, IconButton, Typography, Tooltip } from '@mui/material'
import ReactImageAppear from 'react-image-appear'
import { makeStyles } from '@mui/styles';
import { Edit, KeyboardReturn, CheckCircle } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 220,
        objectFit: "contain",
        width: "100%",
    }
}))

export default function ImgCard(props) {
    const classes = useStyles();
    return (
        <Box
            maxWidth={{ xs: 200, }}
            sx={{
                backgroundColor: "white",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                borderRadius: "5px",
                cursor: "pointer",
                "&:hover": {
                    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)"
                }
            }}
            margin="auto"
        >
            {!props.editImg ?
                <ReactImageAppear
                    className={classes.media}
                    src={window.$api + '/getCourseImg/' + props.img}
                    animation="zoomIn"
                />
                :
                null
            }

            {props.editImg ?
                <ReactImageAppear
                    className={classes.media}
                    src={props.editImg}
                    animation="zoomIn"
                />
                :
                null
            }
            <Box
                padding={{ xs: "4px 16px" }}
                display="flex"
                justifyContent="space-between"
            >
                <Box >
                    <Tooltip title={props.fullTitle} enterDelay={1000}>
                        <Typography fontSize={{ xs: 18 }}><b>{props.title}</b></Typography>
                    </Tooltip>



                    {Number(props.promo_price) > 0 ?
                        <>
                            <Typography fontSize={{ xs: 14 }} color="red"><del>{props.subTitle}</del></Typography>
                            <Typography fontSize={{ xs: 14 }} color="blue">
                                <NumericFormat
                                    value={props.promo_price}
                                    displayType={'text'}
                                    suffix={' ກີບ'}
                                    thousandSeparator={true}
                                />
                            </Typography>
                        </>
                        :
                        <>
                            <Typography fontSize={{ xs: 14 }}>{props.subTitle}</Typography>
                        </>

                    }

                </Box>
                {props.edit &&
                    <Box display='flex'>
                        <Tooltip title="ວາງຂາຍສິນຄ້າ" enterDelay={1000}>
                            <IconButton onClick={props.onConfirm}>
                                <CheckCircle sx={{ fontSize: { xs: 22, } }} />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="ແກ້ໄຂສິນຄ້າ" enterDelay={1000}>
                            <IconButton onClick={props.onEdit}>
                                <Edit sx={{ fontSize: { xs: 22 } }} />
                            </IconButton>
                        </Tooltip>

                    </Box>
                }
                {props.return &&
                    <Box>
                        <IconButton onClick={props.onReturn}>
                            <KeyboardReturn />
                        </IconButton>
                    </Box>
                }
            </Box>
        </Box>
    )
}
