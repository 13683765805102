import React from 'react'
import { makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import Visitor from '../../../modules/Visitor/Visitor'

const useStyles = makeStyles((theme) => ({
    bg: {
        backgroundColor: theme.palette.primary.main,
    },
    topic: {
        color: theme.palette.primary.main,
    },
    outlineImg: {
        backgroundColor: theme.palette.secondary.light,
        padding: '1%'
    },
    text: {
        fontSize: '0.4rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.6rem',
        },
    },
    text1: {
        color: theme.palette.background.default,
        fontWeight: '300',
        fontSize: '0.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
    },
    bgFt: {
        backgroundColor: theme.palette.background.default,
        padding: '0.3%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    lineFt: {
        backgroundColor: theme.palette.warning.main,
        padding: '0.5%'
    },
}))

export default function Footer(props) {

    const classes = useStyles();

    return (
        <div className={classes.bg}>
            <Grid container>
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', padding: '2%' }}>

                    <Typography variant='subtitle1' className={classes.text1}>{props.t('footer.3')}</Typography>
                    <Typography variant='subtitle1' className={classes.text1}>{props.t('footer.4')}</Typography>
                    <Typography variant='subtitle1' className={classes.text1}>{props.t('info.7')}: +856-20 22245222; 22431122</Typography>
                    <br />
                    <Typography variant='h6' className={classes.text1} style={{ fontWeight: '300', display: 'flex', justifyContent: 'center' }}>{props.t('visitor.1')} &nbsp; <Visitor view={props.view} /> </Typography>

                </div>
            </Grid>

            <div >
                <Grid container className={classes.bgFt}>
                    <Typography variant='h6' className={classes.text} style={{ fontWeight: '300' }}>Copyright&copy; 2022</Typography>
                    <Typography variant='h6' className={classes.text} style={{ display: 'flex', fontWeight: '300', }}>{props.t('footer.2')} <div><Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '75%', paddingTop: '5%', }}><a style={{ textDecoration: 'none', cursor: 'pointer' }} href="https://www.besttech.la/">&nbsp;BESTTECH</a> </Typography></div></Typography>
                </Grid>
            </div>
            <div className={classes.lineFt} />
        </div>
    )
}
