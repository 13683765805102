import { Container, Typography, Divider, Grid } from '@mui/material';
import React from 'react'
import { makeStyles } from '@mui/styles';
import GoogleMap from '../../../modules/GoogleMap/GoogleMap';
import TextInput from '../../../modules/TextInput/TextInput';

const useStyles = makeStyles((theme) => ({

    text1: {
        //color: theme.palette.primary.main,
        //textAlign: 'center',
        fontWeight: '700',
        fontSize: '0.9rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.7rem',
        },
    },
    text2: {
        //color: theme.palette.secondary.main,
        fontWeight: '400',
        fontSize: '0.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
    },
    topic: {
        //color: theme.palette.secondary.dark,
        fontSize: '17px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.5vw',

        },
        fontWeight: 'bold'
    },
    underLine: {
        //width: '12.2vw',
        width: '83px',
        [theme.breakpoints.up('sm')]: {
            width: '12.5vw',
        },
        backgroundColor: theme.palette.primary.main,
    },
    underLineEn: {
        //width: '11vw',
        width: '85px',
        [theme.breakpoints.up('sm')]: {
            width: '12vw',
        },
        backgroundColor: theme.palette.primary.main,
    },
    underLine2: {
        backgroundColor: theme.palette.primary.main,
    },
}));

export default function ContactPage(props) {
    const classes = useStyles();

    return (
        <Container maxWidth='false' style={{ padding: '5%' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {props.i18n.language === "la" ?
                        <>
                            <Typography component={'div'}>
                                <div className={classes.topic}>{props.t("title.3")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></div>
                            </Typography>
                            <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                        </>
                        :
                        <>
                            <Typography component={'div'}>
                                <div className={classes.topic}>{props.t("title.3")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLineEn} /></div>
                            </Typography>
                            <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                        </>
                    }
                    <br />
                </div>
            </div>
            <br /><br />
            <Grid container>
                <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
                    <GoogleMap />
                </Grid>
                <Grid item container xs={12} sm={12} md={6} lg={6} xl={6} >
                    <Grid container>
                        <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
                            <div >
                                <div style={{ textAlign: '' }}>
                                    <Typography variant='h4' >
                                        <div className={classes.text1}>
                                            {props.t("info.3")}
                                        </div>
                                    </Typography>&nbsp;
                                </div>

                                <div>
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap", fontWeight: 'bold' }}>{props.t('contact.1')}: </Typography>
                                    <Typography variant='h6' className={classes.text2}>{props.t('contact.2')}</Typography>
                                    <Typography variant='h6' className={classes.text2}>{props.t('contact.3')}</Typography>
                                    <Typography variant='h6' className={classes.text2}>{props.t('info.7')}: +85620 2224 5222; 2243 1122</Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item container xs={6} sm={6} md={6} lg={6} xl={6} style={{ justifyContent: 'center', }}>
                            <div >
                                <div style={{ textAlign: '' }}>
                                    <Typography variant='h4' >
                                        <div className={classes.text1}>
                                            {props.t("info.2")}
                                        </div>
                                    </Typography>&nbsp;&nbsp;&nbsp;
                                </div>

                                <div >
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap", fontWeight: 'bold' }} >{props.t("address.1")}</Typography>
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap" }} >{props.t("address.2")}</Typography>
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap", fontWeight: 'bold' }} >{props.t("address.3")}:</Typography>
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap" }} >{props.t("address.4")}</Typography>
                                    <Typography variant='h6' className={classes.text2} style={{ whiteSpace: "pre-wrap" }} >{props.t("address.5")}</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <br /><br /><br />
            <Grid container style={{ justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <Typography variant='h6' className={classes.text1} component={'div'}>{props.t('contact.4')}</Typography>
                </div>
            </Grid>
            <TextInput t={props.t} />
        </Container >
    )
}

