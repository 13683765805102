import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material'
import PublicApp from './apps/public/index'
import AdminApp from './apps/admin/index'
import Phetsarath from './fonts/PhetsarathOTMac.ttf'
import './i18n'

//window.$api = 'https://rest.yordxam.com:4343/api'; //local
window.$api = 'https://srv.yordxam.com'; //server

const theme = createTheme({
  palette: {
    ///type: "dark",
    background: {
      default: "#FEFEFE",
    },
    primary: {
      light: "#8DDEB8",  
      main: "#268F5E"
      
    },
    secondary: {
      light: '#E6E6E6',
      main: '#666666'
    },
    warning:{
      main: "#F6931E",
      light: '#FDD4A3'
    }
  },
  typography: {
    fontFamily: [
      'Phetsarath OT',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: [
          'Phetsarath OT',
          'sans-serif',
        ].join(','),
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Phetsarath OT';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Phetsarath OT'), url(${Phetsarath}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme.typography.h3 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '1.0rem',
  },
};

theme.typography.h2 = {
  [theme.breakpoints.down('md')]: {
    fontSize: '1.0rem',
  },
};

theme.typography.h4 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
  },
};

theme.typography.h5 = {
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h6 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.2rem',
  },
};

theme.typography.subtitle1 = {
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.0rem',
  },
};
theme.typography.body1 = {
  [theme.breakpoints.down('md')]: {
    fontSize: '0.3rem',
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Routes>
        <Route path="admin/*" element={<AdminApp />} />
        <Route path="/*" element={<PublicApp />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

