import React from "react";
import { Typography, Grid, ButtonBase, Paper, CardMedia } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { NumericFormat } from 'react-number-format';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: 20
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    clickArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    paper: {
        padding: 10,
        spacing: 10,
        margin: 10,
        display: 'flex',
        //minWidth: 327
    },
    img: {
        margin: 'auto',
        //display: 'block',
        maxWidth: '90%'
    },
    descArea: {
        fontSize: '120%',
        width: '100%'
    },
    btnBgPrv: {
        //padding: '1.5% 4%', 
        width: '30%', 
        cursor: 'pointer', 
        backgroundColor: theme.palette.primary.light, 
        textAlign: 'center',
        borderRadius: 8
    },
    btnBgNext: {
        //padding: '1% 3%', 
        width: '30%', 
        cursor: 'pointer', 
        backgroundColor: theme.palette.primary.light, 
        textAlign: 'center',
        borderRadius: 8
    }
}));

export default function CardRow(props) {
    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paper}>
                <Grid container>
                    {props.cardInfo.img_id &&
                        <Grid item container xs={12} sm={2} md={2.5} xl={2.5} lg={2.5} >
                            <CardMedia
                                component="img"
                                height="auto"
                                width="100%"
                                src={window.$api + '/getCourseImg/' + props.cardInfo.img_id}
                                alt="Paella dish"
                                className={classes.outlineImg}
                            />
                        </Grid>
                    }
                    <Grid item container xs={12} sm={7} md={7.5} xl={7.5} lg={7.5} direction="column">
                        <ButtonBase className={classes.clickArea}>
                            <Grid item>
                                <Typography component="h5" variant="h5" color="primary" >
                                    {props.cardInfo.product_name}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {props.cardInfo.promo_price > 0 ?
                                    <>
                                        <NumericFormat
                                            value={props.cardInfo.price}
                                            displayType={'text'}
                                            suffix={' ' + props.t('mn.1')}
                                            thousandSeparator={true}
                                            renderText={value =>
                                                <div>
                                                    <Typography component="h5" variant="h5" color="primary">
                                                        <del>{value}</del>
                                                    </Typography>
                                                </div>
                                            }
                                        />

                                        <NumericFormat
                                            value={props.cardInfo.promo_price}
                                            displayType={'text'} suffix={' ' + props.t('mn.1')}
                                            thousandSeparator={true}
                                            renderText={value =>
                                                <div>
                                                    <Typography component="h5" variant="h5" color="error">
                                                        {value}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    </>
                                    :
                                    <>
                                        <NumericFormat
                                            value={props.cardInfo.price}
                                            displayType={'text'}
                                            suffix={' ' + props.t('mn.1')}
                                            thousandSeparator={true}
                                            renderText={value =>
                                                <div>
                                                    <Typography component="h5" variant="h5" color="error">
                                                        {value}
                                                    </Typography>
                                                </div>
                                            }
                                        />
                                    </>
                                }

                            </Grid>
                            <Grid item align="left" className={classes.descArea} >

                            </Grid><br />
                            <Grid item>

                            </Grid>
                        </ButtonBase>
                    </Grid>
                    <Grid item container xs={12} sm={3} md={2} xl={2} lg={2}>
                        <Grid container direction="column">
                            <Grid item container xs={1} sm={6} md={6} xl={6} lg={6} style={{  justifyContent: 'flex-end',}}>
                                <div >
                                    <div style={{ padding: '1%', cursor: 'pointer'}} onClick={()=> props.handleDelete(props.cardInfo.id)}>
                                        <DeleteForeverIcon/>
                                    </div>
                                    
                                </div>
                            </Grid>
                            <Grid item container xs={1} sm={6} md={6} xl={6} lg={6} style={{ alignItems: 'center', justifyContent: 'center',}}>
                                <div style={{ display: 'flex', padding: '', width: '100%' }}>
                                    <div onClick={()=> props.handleDecreaseQty(props.index)} className={classes.btnBgPrv}> <RemoveIcon sx={{fontSize: 20}}/> </div>
                                    <div style={{ padding: '', width: '40%', textAlign: 'center'}}> {props.cardInfo.qty || 1} </div>
                                    <div onClick={()=> props.handleIncreaseQty(props.index)} className={classes.btnBgNext}> <AddIcon sx={{fontSize: 20}}/> </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Paper>
        </div>
    );
};
