import { Button, Dialog, DialogContent, DialogTitle, Typography} from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import Backdrop from '../Backdrop/Backdrop';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        //textAlign: 'center',
        color: theme.palette.background.default,
        justifyContent: 'center'
    },
    bgcolor: {
        backgroundColor: theme.palette.secondary.light,
        padding: '1%'
    },
    bgTitleContent: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        //backgroundColor: 'red',
    },
    content: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default function Alert(props) {
    const classes = useStyles();

    return (
        <Dialog onClose={props.handleCancel} open={props.open} aria-labelledby="form-dialog-title" scroll="body">
            <DialogTitle id="form-dialog-title" className={classes.bgTitleContent}>
                {props.title}
            </DialogTitle>
            <div style={{ padding: '2%' }}>
                <DialogContent className={classes.bgcolor}>
                    <Typography variant='h6'>{props.text}</Typography>
                        {props.content}
                </DialogContent>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
                <Button variant="contained" style={{ textTransform: 'none' }} onClick={props.handleSubmit}>
                    {props.ok}
                </Button>&nbsp;&nbsp;
                <Button variant="contained" style={{ textTransform: 'none' }} onClick={props.handleCancel}>
                    {props.cancel}
                </Button>
            </div>
            <Backdrop open={props.openBackdrop} />
        </Dialog>
    )
}
