import React from 'react'
import ReactLoading from 'react-loading';

export default function Loading() {
    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh"}}>
            {/* <ReactLoading type="balls" color='red' height={667} width={375} />
            <ReactLoading type="bars" color='red' height={667} width={375} /> */}
            <div >
            <ReactLoading type="bubbles" color={'green'} height={300} width={200} />
            </div>
            
            {/* <ReactLoading type="cubes" color='red' height={667} width={375} />
            <ReactLoading type="cylon" color='red' height={667} width={375} /> */}
            {/* <ReactLoading type="spin" color='red' height={667} width={375} />
            <ReactLoading type="spokes" color='red' height={667} width={375} />
            <ReactLoading type="spinningBubbles" color='red' height={667} width={375} /> */}
        </div>

    )
}
