import React from 'react'
import { Container, Divider, Typography, CardHeader, Grid, Button } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CardListRow from '../CardListRow/CardListRow';
import DialogInputInfo from '../Popup/DialogInputInfo.js'
import PopupWarning from '../Popup/PopupWarning';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        [theme.breakpoints.up('sm')]: {
            fontSize: '23px'
        },
        fontSize: '13px'
    },
    root: {
        backgroundColor: theme.palette.background.default,
        borderRadius: 8
    },
    root1: {
        marginTop: "2%",
        marginBottom: "3%",
        width: "100%",
        margin: "auto"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        padding: '2%',
        borderRadius: 3,
        color: theme.palette.background.default,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            transition: '1s',
        }
    },
    topic: {
        fontSize: '17px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.5vw',

        },
        fontWeight: 'bold'
    },
    underLine: {
        
        backgroundColor: theme.palette.primary.main,
        width: '70px',
        [theme.breakpoints.up('sm')]: {
            width: '10.5vw',
        },
    },
    underLineEn: {
        backgroundColor: theme.palette.primary.main,
        width: '97px',
        [theme.breakpoints.up('sm')]: {
            width: '14vw',
        },
    },
    underLine2: {
        backgroundColor: theme.palette.primary.main,
    },
    price: {
        fontSize: '10px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',

        },
    }
}));

export default function ProductCart(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false)
    const [openWarning, setOpenWarning] = React.useState(false)
    const [purchaseInfo, setpurchaseInfo] = React.useState({})


    const openInputInfo = () => {
        if (props.cartList.length) {
            setOpen(true)
        } else {
            setOpenWarning(true)
        }
    }

    const closeWarning = () => {
        setOpenWarning(false)
    }

    const closeInputInfo = () => {
        setOpen(false)
    }

    const handlePayment = () => {
        axios({
            url: window.$api + "/order",
            method: "POST",
            headers: {
                "Accept": "application/json",
                "content-Type": "application/json;charset-UTF-8"
            },
            data: {
                orderInfo: purchaseInfo,
                cart: props.cartList,
                total: props.cartList.reduce((partialSum, a) =>
                    Number(partialSum) + (Number(a.promo_price) > 0 ? Number(a.promo_price) * (Number(a.qty) || 0) : Number(a.price) * (Number(a.qty) || 0))
                    , 0)
            }
        }).then(res => {
            if (res.data === 1) {
                setpurchaseInfo({})
                props.setcartList([])
                var cart = []
                localStorage.setItem("cart", JSON.stringify(cart))
                setOpen(false)
                alert('success')
            } else {
                alert('fail')
                setOpen(false)
            }
        })
    }

    const handleDelete = (id) => {
        //var cart = JSON.parse(localStorage.getItem("cart") || '[]')

        var cart = [...props.cartList]
        const del = cart.filter(update => update.id !== id);
        props.setcartList(del)
        localStorage.setItem("cart", JSON.stringify(del))
    }

    const handleIncreaseQty = (index) => {
        var cart = [...props.cartList]
        cart[index].qty = (cart[index].qty || 1) + 1
        props.setcartList(cart)
        localStorage.setItem("cart", JSON.stringify(cart))
    }

    const handleDecreaseQty = (index) => {
        var cart = [...props.cartList]
        var qty = cart[index].qty || 1
        if (qty > 1) {
            cart[index].qty = qty - 1
            props.setcartList(cart)
            localStorage.setItem("cart", JSON.stringify(cart))
        }
    }

    const onChangeInputField = (e) => {
        setpurchaseInfo({
            ...purchaseInfo,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectMenu = (id, menu) => {
        setpurchaseInfo({
            ...purchaseInfo,
            express: menu
        })
    }


    return (
        <div>
            <Container maxWidth='false' style={{ padding: '5%' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        {props.i18n.language === "la" ?
                            <>
                                <Typography component={'div'}>
                                    <div className={classes.topic}>
                                        {props.t("cart.3")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} />
                                    </div>
                                </Typography>
                                <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                            </>
                            :
                            <>
                                <Typography component={'div'}>
                                    <div className={classes.topic}>
                                        {props.t("cart.3")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLineEn} />
                                    </div>
                                </Typography>
                                <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                            </>
                        }
                        <br />
                    </div>
                </div>
                <br /><br />

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={8} xl={8} lg={8} >
                        <CardHeader
                            title={props.t("have.1") + "  " + props.cartList.length + "  " + props.t("cart.4")}
                            className={classes.cardHeader}
                        >
                        </CardHeader>
                        <Grid item lg={12}>
                            <CardListRow
                                cardsInfo={props.cartList}
                                textLen={270}
                                handleDelete={handleDelete}
                                handleIncreaseQty={handleIncreaseQty}
                                handleDecreaseQty={handleDecreaseQty}
                                t={props.t}
                            />
                        </Grid>
                    </Grid>

                    <Grid className={classes.sidebar} item container direction="column" xs={12} sm={12} md={4} xl={4} lg={4}>
                        <Grid item container direction="column">
                            <CardHeader
                                title={props.t("pay.2")}
                                className={classes.cardHeader}
                            />
                        </Grid>
                        <br />

                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1%' }}>
                            <Grid item>

                                <NumericFormat
                                    value={props.cartList.reduce((partialSum, a) =>
                                        Number(partialSum) + (Number(a.promo_price) > 0 ? Number(a.promo_price) * (Number(a.qty) || 0) : Number(a.price) * (Number(a.qty) || 0))
                                        , 0)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={value =>
                                        <Typography variant="h6">
                                            <div className={classes.price}>
                                                {props.t("price.1")}: {value}
                                            </div>
                                        </Typography>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">
                                    <div className={classes.price}>
                                        {props.t("mn.1")}
                                    </div>
                                </Typography>
                            </Grid>
                        </div>

                        <Divider />
                        <br />

                        <Grid item>
                            <Button variant="contained" color="primary" fullWidth onClick={openInputInfo}>
                                {props.t("pay.1")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <DialogInputInfo value={purchaseInfo} onChange={onChangeInputField} handleSelectMenu={handleSelectMenu} t={props.t} open={open} closeInputInfo={closeInputInfo} handlePayment={handlePayment} />
            <PopupWarning t={props.t} open={openWarning} close={closeWarning} />
            <br /><br /><br /><br /><br /><br />
        </div>
    )
}
