import React from "react";
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography, } from "@mui/material";
import { makeStyles } from '@mui/styles';
//import NumberFormat from 'react-number-format';
import ReactImageAppear from 'react-image-appear';
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 202,
    minWidth: 202,
    borderRadius: 5,
    boxShadow: "none",
    //size of monito "LG"
    [theme.breakpoints.down('lg')]: {
      maxWidth: 202,
      minWidth: 202,
      borderRadius: 5,
      boxShadow: "none",
    },
  },
  media: {
    height: 140,
    objectFit: "contain",
    width: "100%",
    //size of monito "lg"
    [theme.breakpoints.down('lg')]: {
      height: 140,
      objectFit: "contain",
      width: "100%",
    },
  },
  root: {
    flexGrow: 1
  },
  fontXS:{ 
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px'
      //fontsubtitle1
    },
  }
}));

export default function ProductCard(props) {
  const classes = useStyles();

  //height: 60 height: 40
  return (
    <Card onClick={() => { props.cardLink(props.card.id) }} className={`card ${classes.card} ${props.card.id ? props.card.id : ""}`}>
      <Card className={classes.card} >
        <CardActionArea>
          {props.card.img_id &&
            <CardMedia >
              <ReactImageAppear
                className={classes.media}
                src={window.$api + '/getCourseImg/' + props.card.img_id}
                animation="zoomIn"
              />
            </CardMedia>
          }
          <div >
            <CardContent style={{}}>
              <Typography style={{fontWeight: 'bold'}} color="" gutterBottom variant="subtitle2">{props.card.product_name}</Typography>
            </CardContent>
            <CardContent style={{ color: "#D5D8DC", minWidth: '' }}>

              {props.card.promo_price > 0 ?
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                  <NumericFormat
                    value={props.card.price}
                    displayType={'text'}
                    suffix={' ' + props.t('mn.1')}
                    thousandSeparator={true}
                    renderText={value =>
                      <div>
                        <Typography variant="subtitle1" color="primary" className={classes.fontXS}>
                          <del>
                            {value}
                          </del>
                        </Typography>
                      </div>
                    }
                  />

                  <NumericFormat
                    value={props.card.promo_price}
                    displayType={'text'}
                    suffix={' ' + props.t('mn.1')}
                    thousandSeparator={true}
                    renderText={value =>
                      <div>
                        <Typography className={classes.fontXS} variant="subtitle1" color="error">
                          {value}
                        </Typography>
                      </div>
                    }
                  />
                </div>
                :
                <>
                  <NumericFormat
                    value={props.card.price}
                    displayType={'text'}
                    suffix={' ' + props.t('mn.1')}
                    thousandSeparator={true}
                    renderText={value =>
                      <div>
                        <Typography className={classes.fontXS} variant="subtitle1" color="error">
                          {value}
                        </Typography>
                      </div>
                    }
                  />
                </>
              }


            </CardContent>
          </div>
        </CardActionArea >
        <CardActions >
        </CardActions>
      </Card>
    </Card>
  );
}