
import React, { useEffect, useState } from 'react'
//import Carousel from '../../../modules/Carousel/Carousel'
import { makeStyles } from '@mui/styles';
import { Grid, Container, Divider, Typography } from '@mui/material';
import CardListGird from '../../../modules/Cards/CardListGird';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2%",
    marginBottom: "3%",
    width: "100%",
    margin: "auto"
  },
  title: {
    marginBottom: 30,
    width: '100%',
  },
  topic: {
    //color: theme.palette.secondary.dark,
    fontSize: '17px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5vw',

    },
    fontWeight: 'bold'
  },
  underLine: {
    //width: '13vw',
    width: '40px',
    [theme.breakpoints.up('sm')]: {
      width: '6vw',
    },
    backgroundColor: theme.palette.primary.main,
  },
  underLineEn: {
    //width: '13vw',
    width: '69px',
    [theme.breakpoints.up('sm')]: {
      width: '8.8vw',
    },
    backgroundColor: theme.palette.primary.main,
  },
  underLine2: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function ProductPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [cardsData, setcardsData] = useState([])


  const OpenDetailProduct = (productID) => {
    navigate("/product/" + productID)
  }


  useEffect(() => {
    axios({
      url: window.$api + "/getPublishedProductInfo",
      method: "GET",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      }
    }).then(res => {
      setcardsData(res.data)
    })
  }, [])


  return (
    <Container maxWidth='false' style={{ padding: '5%' }}>
      <div className={classes.root}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {props.i18n.language === "la" ?
              <>
              <Typography component={'div'} >
                <div className={classes.topic}>{props.t("title.1")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></div>
                </Typography>
                <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
              </>
              :
              <>
              <Typography component={'div'} >
                <div className={classes.topic}>{props.t("title.1")}<Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLineEn} /></div>
                </Typography>
                <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} /></>
            }
            <br />
          </div>
        </div>
        <br /><br />
        <Grid container direction="row" style={{ height: '100%', padding: '', maxHeight: 800, overflow: 'scroll'}} spacing={1} >
          {cardsData.map(card => (
            <Grid item xs={12} sm={5} md={4} lg={2.3} xl={2} key={card.id} style={{ padding: '', backgroundColor: '' }}  >
              <CardListGird card={card} t={props.t} cardLink={OpenDetailProduct} />
            </Grid>
          ))}
        </Grid>
      </div>

    </Container>
  )
}
//