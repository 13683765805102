import React from 'react'
import { Container, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Cards from '../../../modules/Cards/CardsAbout';

const useStyles = makeStyles((theme) => ({

    text1: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        //fontWeight: 'bold'
    },
    text2: {
        color: theme.palette.secondary.main,
    },
    topic: {
        fontWeight: 'bold',
        //color: theme.palette.secondary.dark,
        fontSize: '17px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.5vw',

        },
    },
    underLine: {
        width: '100px',
        [theme.breakpoints.up('sm')]: {
            width: '15vw',
        },
        backgroundColor: theme.palette.primary.main,
    },
    underLineEn: {
        width: '70px',
        [theme.breakpoints.up('sm')]: {
            width: '10vw',
        },
        backgroundColor: theme.palette.primary.main,
    },
    underLine2: {
        backgroundColor: theme.palette.primary.main,
    },
}));
export default function AboutusPage(props) {
    const classes = useStyles();

    return (
        <Container maxWidth='false' style={{ height: '100%', padding: '5%' }}>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    {props.i18n.language === "la" ?
                        <>
                            <Typography component={'div'} ><div className={classes.topic}>{props.t("title.2")}</div><Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLine} /></Typography>
                            <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                        </>
                        :
                        <>
                            <Typography component={'div'} ><div className={classes.topic}>{props.t("title.2")}</div><Divider sx={{ borderBottomWidth: '0.5vw' }} className={classes.underLineEn} /></Typography>
                            <Divider sx={{ borderBottomWidth: 3 }} className={classes.underLine2} />
                        </>
                    }
                    <br />
                </div>
            </div>
            <Cards i18n={props.i18n} />
        </Container>

    )
}
