import React, { useState } from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import Backdrop from '../../modules/Backdrop/Backdrop'

const useStyles = makeStyles((theme) => ({
    text1: {
        //color: theme.palette.primary.main,
        //textAlign: 'center',
        fontWeight: '400',
        fontSize: '0.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
    },
    textField: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: 500,
        // fontSize: '1.2rem',
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: '1rem',

        // },
        fontSize: '20rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',

        },
    },
    input: {
        color: 'white',
        fontSize: 20
    },
    space: {
        padding: '0.2%'
    },
    send: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: 3,
        padding: '1%',
        textAlign: 'center',
        //color: theme.palette.background.default,
        marginTop: '2%',
        cursor: 'pointer',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '15%',

        },
    }
}));

export default function TextInput(props) {
    const classes = useStyles();
    const [firstName, setFirstname] = useState("")
    const [lastName, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")
    const [openBackdrop, setopenBackdrop] = useState(false)

    const handleSubmit = () => {
        if (firstName && lastName && email && phone && subject && description) {
            setopenBackdrop(true)
            const options = {
                url: window.$api + "/contactus",
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "content-Type": "application/json;charset-UTF-8"
                },
                data: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                    subject: subject,
                    description: description
                }
            };
            axios(options).then(res => {
                //console.log(res.data)
                if (res.data) {
                    setopenBackdrop(false)
                    switch (res.data) {
                        case 1:
                            setFirstname('')
                            setLastname('')
                            setEmail('')
                            setPhone('')
                            setSubject('')
                            setDescription('')
                            alert("success")
                            
                            break;
                        default:
                            setFirstname('')
                            setLastname('')
                            setEmail('')
                            setPhone('')
                            setSubject('')
                            setDescription('')
                            alert("fail")
                            break;
                    }
                } else {
                    setopenBackdrop(false)
                    alert("fail")
                }

            })
        } else {
            alert("empty field")
        }
    }

    return (
        <div style={{ padding: '0.5%' }}>
            <Grid container>
                <Grid item xs={6} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.4")}</Typography>
                    <TextField
                        id="name"
                        label={props.t("info.4")}
                        className={classes.textField}
                        margin="normal"

                        value={firstName || ""}
                        inputProps={{style: {fontSize: 13}}}
                        InputLabelProps={{style: {fontSize: 13}}}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.5")}</Typography>
                    <TextField
                        id="name"
                        label={props.t("info.5")}
                        className={classes.textField}
                        margin="normal"
                        value={lastName || ""}
                        inputProps={{style: {fontSize: 13}}}
                        InputLabelProps={{style: {fontSize: 13}}}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.6")}</Typography>
                    <TextField
                        id="email"
                        label={props.t("info.6")}
                        className={classes.textField}
                        margin="normal"
                        value={email || ""}
                        inputProps={{style: {fontSize: 13}}}
                        InputLabelProps={{style: {fontSize: 13}}}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={6} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.7")}</Typography>
                    <TextField
                        id="phone"
                        label={props.t("info.7")}
                        className={classes.textField}
                        margin="normal"
                        value={phone || ""}
                        inputProps={{style: {fontSize: 13}}}
                        InputLabelProps={{style: {fontSize: 13}}}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.8")}</Typography>
                    <TextField
                        id="subject"
                        label={props.t("info.8")}
                        className={classes.textField}
                        margin="normal"
                        value={subject || ""}
                        inputProps={{style: {fontSize: 13}}}
                        InputLabelProps={{style: {fontSize: 13}}}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} className={classes.space}>
                    <Typography variant='h6' className={classes.text1}>{props.t("info.9")}</Typography>
                    <TextField
                        id="des"
                        label={props.t("info.9")}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={12}
                        value={description || ""}
                        InputLabelProps={{style: {fontSize: 13}}}
                        inputProps={{style: {fontSize: 13}}}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Grid>
                <div className={classes.send}>
                    <Typography variant='h6' className={classes.text1} onClick={handleSubmit}>
                        {props.t("send.1")}
                    </Typography>
                </div>
            </Grid>
            <Backdrop
                open={openBackdrop}
            />

            {/* <div style={{ textAlign: 'center', backgroundColor: 'red', width: '15%', justifyContent: 'center'}}>
                <Button fullWidth variant='contained'>Send</Button>
            </div> */}
        </div>
    )
}
