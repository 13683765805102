
import { Divider, Typography, Badge, Box, IconButton, Menu, MenuItem, } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import Logo from '../../images/logo-yordxam.jpeg'
import Lao from '../../images/flag/Lao.png'
import Eng from '../../images/flag/English.png'
import Carousel from '../Carousel/CarouselSlideBanner';
import { useNavigate, useLocation } from "react-router-dom";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({

    badge: {
        color: theme.palette.primary.dark,
    },
    btnAct: {
        //color: theme.palette.background.default,
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.background.default,
        padding: '1%',
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.background.default,
            transition: '1s',
        },
        cursor: 'pointer',
        justifyContent: 'center',
        borderRadius: 8,
    },
    btn: {
        //color: theme.palette.background.default,
        padding: '1%',
        // '&:hover': {
        //     backgroundColor: theme.palette.warning.main,
        //     color: theme.palette.background.default,
        //     transition: '1s',
        // },
        cursor: 'pointer',
        justifyContent: 'center',
        borderRadius: 8,
    },
    txtTitle: {
        fontSize: '5.5px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2vw',
        },
    },
    topic: {
        color: theme.palette.secondary.dark,
    },
    underLine: {
        width: '13vw',
        backgroundColor: theme.palette.primary.main,
    },
    underLine2: {
        backgroundColor: theme.palette.primary.main,
    },
    logo: {
        width: '100%',
        [theme.breakpoints.down('1010')]: {
            width: '100px',
        },
        // [theme.breakpoints.down('900')]: {
        //     width: '900px',
        // },
        [theme.breakpoints.down('md')]: {
            width: '90px',
        },
        [theme.breakpoints.down('589')]: {
            width: '50px',
            backgroundColor: 'red',
        },
        [theme.breakpoints.down('500')]: {
            width: '30px',
            backgroundColor: 'red',
        },
    },
    lineBar1: {
        backgroundColor: theme.palette.secondary.light,
        padding: '0.2%'
    },
    icon: {
        fontSize: '1.5vw',
        [theme.breakpoints.down('lg')]: {
            fontSize: '25px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '20px',
        },
    },
    iconLng: {
        width: '20%',
        [theme.breakpoints.up('xs')]: {
            width: '20px',
        },
    },
    paddingLogo: {
        [theme.breakpoints.up('xs')]: {
            padding: '15%',
        },
        [theme.breakpoints.down('1025')]: {
            padding: '19%',
        },
        [theme.breakpoints.down('900')]: {
            padding: '15%',
        },
        [theme.breakpoints.down('779')]: {
            padding: '16%',
        },
        [theme.breakpoints.down('749')]: {
            padding: '18%',
        },
        [theme.breakpoints.down('604')]: {
            padding: '16%',
        },
        [theme.breakpoints.down('329')]: {
            padding: '15%',
        },
        padding: '20%',
    }
}));

export default function Navbar(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [active, setActive] = React.useState()

    React.useEffect(() => {
        if (location.pathname === '/') {
            setActive(location.pathname)
        } else if (location.pathname === '/products') {
            setActive(location.pathname)
        } else if (location.pathname === '/aboutus') {
            setActive(location.pathname)
        } else if (location.pathname === '/contactus') {
            setActive(location.pathname)
        }

    }, [location.pathname])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const GoHome = (act) => {
        navigate("/")
        setActive(act)
    }
    const GoProducts = (act) => {
        navigate("/products")
        setActive(act)
    }
    const GoAboutus = (act) => {
        navigate("/aboutus")
        setActive(act)
    }
    const GoContact = (act) => {
        navigate("/contactus")
        setActive(act)
    }

    const gotocart = (act) => {
        navigate("/cart")
        setActive(act)
    }

    //console.log(props.cartList.length)

    return (
        <div>
            <br />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }} />
                <div style={{ flex: 3, }}>

                    <div style={{ display: 'flex', }} >
                        <div style={{ flex: 2, flexDirection: 'column', padding: '1%' }}>
                            <div style={{ flex: 8,  }} className={classes.paddingLogo} />
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <div className={active === '/' ? classes.btnAct : classes.btn} onClick={() => { GoHome('/') }} >
                                    <Typography noWrap variant='h6' className={classes.txtTitle}>{props.t('home.1')}</Typography>
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <div className={active === '/products' ? classes.btnAct : classes.btn} onClick={() => { GoProducts('/products') }}>
                                    <Typography noWrap variant='h6' className={classes.txtTitle}>{props.t("title.1")}</Typography>
                                </div>

                            </div>
                            <Divider variant="fullWidth" />
                        </div>


                        <div style={{ backgroundColor: '', flex: 1, cursor: 'pointer', paddingLeft: '3%', }} onClick={GoHome}>
                            <img src={Logo} alt="" className={classes.logo} />
                        </div>

                        <div style={{ flex: 3, flexDirection: 'column', padding: '1%' }}>
                            <div style={{ flex: 8, padding: '10%' }} />
                            <div style={{ flex: 1, display: 'flex', textAlign: 'center' }}>
                                <div style={{ flex: 1.5 }} className={active === '/aboutus' ? classes.btnAct : classes.btn} onClick={() => { GoAboutus('/aboutus') }}>
                                    <Typography noWrap variant='h6' className={classes.txtTitle}>{props.t("title.2")}</Typography>
                                </div>
                                &nbsp;&nbsp;
                                <div style={{ flex: 1.5, textAlign: 'center' }} className={active === '/contactus' ? classes.btnAct : classes.btn} onClick={() => {GoContact('/contactus')}}>
                                    <Typography noWrap variant='h6' className={classes.txtTitle}>{props.t("title.3")}</Typography>
                                </div>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end', alignItems: 'center', marginLeft: '10%', }}>
                                    <div style={{ flex: 3, justifyContent: 'flex-end', display: 'flex', padding: '1%', cursor: 'pointer' }} onClick={gotocart}>
                                        <Badge badgeContent={props.cartList.length} color="warning" sx={{ "& .MuiBadge-badge": { color: "white" },  }}>
                                            <ShoppingCartOutlinedIcon className={classes.icon} fontSize="20px" />
                                        </Badge>
                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div style={{ flex: 1, padding: '1%' }}>
                                        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                            <img src={Lao} alt="" style={{ width: '20px', cursor: 'pointer' }} onClick={() => props.i18n.changeLanguage("la")} />
                                        </div>
                                    </div>
                                    <div style={{ flex: 1, padding: '1%' }}>
                                        <div style={{ justifyContent: 'flex-start', display: 'flex' }}>
                                            <img src={Eng} alt="" style={{ width: '20px', cursor: 'pointer' }} onClick={() => props.i18n.changeLanguage("en")} />
                                        </div>
                                    </div>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', position: 'absolute', marginLeft: '30%', marginTop: '-5%' }}>
                                    <IconButton
                                        //size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        color="inherit"
                                        onClick={handleOpenNavMenu}
                                    >
                                        <MenuIcon style={{ fontSize: 15 }} />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >

                                        <MenuItem >
                                            <div>
                                                <IconButton onClick={() => { props.i18n.changeLanguage("la"); handleCloseNavMenu(); }}>
                                                    <img src={Lao} alt="" fontSize="" className={classes.iconLng} />
                                                </IconButton>
                                            </div>
                                        </MenuItem>

                                        <MenuItem >
                                            <div>
                                                <IconButton onClick={() => { props.i18n.changeLanguage("en"); handleCloseNavMenu(); }}>
                                                    <img src={Eng} alt="" fontSize="" className={classes.iconLng} />
                                                </IconButton>
                                            </div>
                                        </MenuItem>

                                        <MenuItem style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div >
                                                <IconButton onClick={() => { gotocart(); handleCloseNavMenu(); }}>
                                                    <Badge badgeContent={props.cartList.length} color="warning" sx={{ "& .MuiBadge-badge": { color: "white" } }}>
                                                        <ShoppingCartOutlinedIcon className={classes.icon} fontSize="" />
                                                    </Badge>
                                                </IconButton>
                                            </div>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </div>
                            <Divider variant="fullWidth" />
                        </div>


                    </div>

                </div>

                <div style={{ flex: 1 }} />

            </div>
            {/* <br />
            <div style={{ textAlign: 'center' }}>
                <Typography variant='h2' style={{ fontSize: '2vw' }}>
                    ວິສາຫະກິດ ຍອດຊຳ ສ່ວນບຸກຄົນ
                </Typography>
                <Typography variant='subtitle1' style={{ fontSize: '1.3vw' }}>
                    YordXam Individual Enterprise
                </Typography>
            </div> */}
            <br />
            <Carousel />
            <div className={classes.lineBar1} />
            <br />
        </div>
    )
}
