import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, TextField, IconButton, Tooltip, Box } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';
import Backdrop from '../Backdrop/Backdrop';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        //textAlign: 'center',
        color: theme.palette.background.default,
        justifyContent: 'center'
    },
    bgcolor: {
        backgroundColor: theme.palette.secondary.light,
        padding: '1%',
        display: "flex",
        flexDirection: "column"
    },
    bgTitleContent: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.light,
        //backgroundColor: 'red',
    },
    content: {
        display: 'flex',
        justifyContent: 'center'
    },
    subimg: {
        //padding: theme.spacing(1),
        backgroundColor: "white",
        borderRadius: "10%",
        margin: "0px 5px 5px",
        cursor: "pointer",
        [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 50
        },
        [theme.breakpoints.up('sm')]: {
            width: 60,
            height: 70
        },
    },
}));

export default function EditProductDiag(props) {
    const classes = useStyles();

    return (
        <div>
            <Dialog onClose={props.onClose} open={props.open} aria-labelledby="form-dialog-title" scroll="body" maxWidth='sm' fullWidth sx={{ padding: "4%" }}>
                <DialogTitle display="flex" justifyContent="space-between">
                    <div style={{ textAlign: 'center' }}>
                        ແກ້ໄຂສິນຄ້າ
                    </div>
                    <Tooltip title="ລຶບສິນຄ້າ" enterDelay={1000}>
                        <IconButton onClick={props.onDelete}>
                            <Delete />
                        </IconButton>
                    </Tooltip>

                </DialogTitle>
                <div style={{ padding: '2%' }}>
                    <DialogContent className={classes.bgcolor}>
                        <Box display='flex'>
                            <Button color="primary" aria-label="upload picture" component="label">
                                {props.img !== "" ?
                                    <img width="50%" src={props.img}  alt='' />
                                    :
                                    <img width="50%" src={window.$api + '/getCourseImg/' + props.value.img_id} alt='' />
                                }
                                <input hidden accept="image/*" type="file" onChange={props.onChangeImg}/>
                            </Button>
                            <Box display='flex' flexDirection='column'>
                                    <Button color="primary" aria-label="upload picture" component="label">
                                        <AddIcon />
                                        <input hidden accept="image/*" type="file" onChange={props.onChangeImgList} />
                                    </Button>
                                {props.imgList.map((row, index) =>
                                    <Button key={index} color="primary" aria-label="upload picture" component="label">
                                        <img id="img_list" src={row.img ? row.img : window.$api + '/getCourseImg/' + row.img_id} alt='' className={classes.subimg} />
                                        <input hidden accept="image/*" type="file" onChange={(e) => props.onEditImgList(e, index)}/>
                                    </Button>
                                )}
                            </Box>
                        </Box>
                        <br />
                        <TextField
                            label="ຊື່ສິນຄ້າ"
                            variant='outlined'
                            value={props.value.product_name || ""}
                            onChange={(e) => props.setValue({ ...props.value, product_name: e.target.value })}
                        />
                        <br />
                        <TextField
                            label="ລາຄາ"
                            variant='outlined'
                            value={props.value.price || ""}
                            onChange={(e) => props.setValue({ ...props.value, price: e.target.value })}
                        />
                        <br />
                        <TextField
                            label="ລາຄາສ່ວນຫຼຸດ"
                            variant='outlined'
                            value={props.value.promo_price || ""}
                            onChange={(e) => props.setValue({ ...props.value, promo_price: e.target.value })}
                        />
                        <br />
                        <TextField
                            label="ຄຳອະບາຍ"
                            variant='outlined'
                            value={props.value.description || ""}
                            onChange={(e) => props.setValue({ ...props.value, description: e.target.value })}
                        />
                        <br />
                    </DialogContent>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', padding: '5%' }}>
                    <Button onClick={props.submit} variant="contained" style={{ textTransform: 'none' }} >
                        ຕົກລົງ
                    </Button>&nbsp;&nbsp;
                    <Button variant="contained" onClick={props.onClose} style={{ textTransform: 'none' }} >
                        ຍົກເລີກ
                    </Button>
                </div>
                {props.openBackdrop &&
                <Backdrop open={props.openBackdrop} />
                }
            </Dialog>
        </div>
    )
}
