import React from "react";
import { Grid } from "@mui/material";
import CardRow from "./CardRow";

export default function CardListRow(props) {
  return (
    <Grid container >

      <Grid item xs={12}>
        {props.cardsInfo.map((cardInfo, index) =>
          <CardRow 
          cardInfo={cardInfo} 
          key={cardInfo.id} 
          handleDelete={props.handleDelete} 
          handleIncreaseQty={props.handleIncreaseQty}
          handleDecreaseQty={props.handleDecreaseQty}
          index={index}
          t={props.t} 
          {...props} 
          />
        )}
      </Grid>
    </Grid>
  );
};