import { Box, Typography, Grid } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ImgCard from '../../../modules/ProductCard/ImgCard'
import EditProductDiag from '../../../modules/Popup/EditProductDiag'
import Alert from '../../../modules/ProductCard/Alert'
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import AuthenWarning from '../../../modules/Popup/AuthenWarning'

export default function EditProduct(props) {
  const navigate = useNavigate()
  const [publishedProductList, setpublishedProductList] = useState([])
  const [unpublishedProductList, setunpublishedProductList] = useState([])
  const [editProduct, seteditProduct] = useState({
    price: 0,
    promo_price: 0
  })
  const [open, setopen] = useState(false)
  const [productId, setproductId] = useState("")
  const [openDelAlert, setopenDelAlert] = useState(false)
  const [openUnpublishAlert, setopenUnpublishAlert] = useState(false)
  const [productname, setproductname] = useState("")
  const [price, setprice] = useState()
  const [imgId, setimgId] = useState("")
  const [openPublishAlert, setopenPublishAlert] = useState(false)
  const [openEditBackdrop, setopenEditBackdrop] = useState(false)
  const [editImg, seteditImg] = useState("")
  const [editImgList, seteditImgList] = useState([])
  const [openDelBackdrop, setopenDelBackdrop] = useState(false)
  //const [origImgList, setorigImgList] = useState([])

  useEffect(() => {
    axios({
      url: window.$api + "/getAdminProductInfo",
      method: "GET",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
    }).then(res => {
      setpublishedProductList(res.data.publishedProductInfo)
      setunpublishedProductList(res.data.unpublishedProductInfo)
    })
  }, [])

  const handleEdit = (id) => {
    axios({
      url: window.$api + "/getEditProductById",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        id: id
      }
    }).then(res => {
      seteditImgList(res.data.imgListInfo)
      //seteditImg(res.data.productInfo.img_id)
      seteditProduct(res.data.productInfo)
      setproductId(id)
      setopen(true)
    })
  }

  const handleSubmitEdit = () => {
    if (editProduct.product_name && editProduct.price && editProduct.description) {
      if (!isNaN(editProduct.price) && !isNaN(editProduct.promo_price)) {
        setopenEditBackdrop(true)
        axios({
          url: window.$api + "/editProduct",
          method: "POST",
          headers: {
            "Accept": "application/json",
            "content-Type": "application/json;charset-UTF-8"
          },
          data: {
            id: productId,
            value: editProduct,
            img: editImg || null,
            imgList: editImgList
          }
        }).then(res => {
          var newArr = [...unpublishedProductList]
          newArr.forEach(el => {
            if (el.id === productId) {
              el.product_name = editProduct.product_name
              el.price = editProduct.price
              el.promo_price = editProduct.promo_price
              el.img = editImg
            }
          });
          setunpublishedProductList(newArr)
          setopen(false)
          setopenEditBackdrop(false)
        }).catch(e => {
          throw e
        })
      } else {
        alert('ກະລຸນາປ້ອນລາຄາເປັນໂຕເລກ')
      }
    } else {
      alert('ກະລຸນາປ້ອນຂໍ້ມູນ')
    }
  }

  const handleCloseDiag = () => {
    setopen(false)
  }

  const handleDelete = () => {
    setopenDelAlert(true)
  }

  const handleCancelDel = () => {
    setopenDelAlert(false)
  }

  const handleConfirmDel = () => {
    setopenDelBackdrop(true)
    axios({
      url: window.$api + "/delProduct",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        id: productId,
      }
    }).then(res => {
      //alert("ສຳເລັດແລ້ວ")
      let newArray = [...unpublishedProductList];
      const del = newArray.filter(update => update.id !== productId);
      setunpublishedProductList(del)
      setopenDelAlert(false)
      setproductId("")
      setopenDelBackdrop(false)
      setopen(false)
    })
  }

  const handleUnpublishProduct = (id, name, price, img_id) => {
    setopenUnpublishAlert(true)
    setproductId(id)
    setproductname(name)
    setprice(price)
    setimgId(img_id)
  }

  const handleConfirmUnpublishProduct = () => {
    axios({
      url: window.$api + "/unPublishProduct",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        id: productId,
      }
    }).then(res => {
      alert("ສຳເລັດແລ້ວ")
      var newArr = [...unpublishedProductList]
      var data = {
        id: productId,
        product_name: productname,
        price: price,
        img_id: imgId
      }
      newArr.push(data)
      setunpublishedProductList(newArr)

      let newArray = [...publishedProductList];
      const del = newArray.filter(update => update.id !== productId);
      setpublishedProductList(del)

      setopenUnpublishAlert(false)
      setproductId("")
    })
  }

  const handleCancelUnpublish = () => {
    setopenUnpublishAlert(false)
    setproductId("")
    setproductname("")
    setprice("")
    setimgId("")
  }

  const handlePublishProduct = (id, name, price, img_id) => {
    setopenPublishAlert(true)
    setproductId(id)
    setproductname(name)
    setprice(price)
    setimgId(img_id)
  }

  const handleCancelPublish = () => {
    setopenPublishAlert(false)
    setproductId("")
    setproductname("")
    setprice("")
    setimgId("")
  }

  const handleConfirmPublishProduct = () => {
    axios({
      url: window.$api + "/publishProduct",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        id: productId,
      }
    }).then(res => {
      alert("ສຳເລັດແລ້ວ")
      var newArr = [...publishedProductList]
      var data = {
        id: productId,
        product_name: productname,
        price: price,
        img_id: imgId
      }
      newArr.push(data)
      setpublishedProductList(newArr)

      let newArray = [...unpublishedProductList];
      const del = newArray.filter(update => update.id !== productId);
      setunpublishedProductList(del)
      setopenPublishAlert(false)
      setproductId("")
      setproductname("")
      setprice("")
      setimgId("")

    })
  }

  const handleClick = () => {
    navigate("/admin/login")
  }

  const onChangeImg = (e) => {
    if (e.target.files[0]) {
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        seteditImg(srcData)
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  }

  const onChangeImgList = (e) => {
    if (editImgList.length < 3) {
      if (e.target.files[0]) {
        var fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64
          var newArr = [...editImgList]
          var data = {
            img: srcData
          }
          newArr.push(data)
          seteditImgList(newArr)
        }
        fileReader.readAsDataURL(e.target.files[0]);
      }
    } else {
      alert("ສາມາດເພີ່ມໄດ້ສູງສຸດ 3 ຮູບ")
    }
  }

  const onEditImgList = (e, index) => {
    if (e.target.files[0]) {
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        var newArr = [...editImgList]
        newArr[index].img = srcData
        seteditImgList(newArr)
      }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  }

  if (props.isLoggedIn) {
    return (
      <Box
        display={{ xs: "flex" }}
        flexDirection={{ xs: "column" }}
        height={{ xs: "100vh" }}
      >
        <Box padding={2} sx={{ backgroundColor: "#B2BEB5", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", margin: "20px" }} flex={1} borderRadius={4}>
          <Typography align='center' fontSize={{ xs: 28 }}><b>ສິນຄ້າກຳລັງສ້າງ</b></Typography>
          {unpublishedProductList.length > 0 ?
            <Grid container direction="row" alignItems="center" spacing={1} padding={2}>
              {unpublishedProductList.map(card => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={card.id} >
                  <ImgCard
                    img={card.img_id}
                    fullTitle={card.product_name}
                    title={String(card.product_name).substring(0, 12) + (String(card.product_name).length > 12 ? '..' : '')}
                    subTitle={
                      <NumericFormat
                        value={card.price}
                        displayType={'text'}
                        suffix={' ກີບ'}
                        thousandSeparator={true}

                      />
                    }
                    promo_price={card.promo_price}
                    editImg={card.img}
                    edit={true}
                    onEdit={() => handleEdit(card.id)}
                    onConfirm={() => handlePublishProduct(card.id, card.product_name, card.price, card.img_id)}
                  />
                </Grid>
              ))}
            </Grid>
            :
            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
              <Typography fontSize={{ xs: 24 }}><b>ບໍ່ມີສິນຄ້າ</b></Typography>
            </Box>
          }
        </Box>
        <Box padding={2} sx={{ backgroundColor: "#B2BEB5", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", margin: "0px 20px " }} flex={1} borderRadius={4}>
          <Typography align='center' fontSize={{ xs: 28 }}><b>ສິນຄ້າທີ່ວາງຂາຍ</b></Typography>
          {publishedProductList.length > 0 ?
            <Grid container direction="row" alignItems="center" spacing={1} padding={2}>
              {publishedProductList.map(card => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={card.id} >
                  <ImgCard
                    img={card.img_id}
                    fullTitle={card.product_name}
                    title={String(card.product_name).substring(0, 16) + (String(card.product_name).length > 16 ? '..' : '')}
                    subTitle={
                      <NumericFormat
                        value={card.price}
                        displayType={'text'}
                        suffix={' ກີບ'}
                        thousandSeparator={true}

                      />
                    }
                    promo_price={card.promo_price}
                    return={true}
                    onReturn={() => handleUnpublishProduct(card.id, card.product_name, card.price, card.img_id)}
                  />
                </Grid>

              ))}
            </Grid>
            :
            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
              <Typography fontSize={{ xs: 24 }}><b>ບໍ່ມີສິນຄ້າ</b></Typography>
            </Box>
          }
        </Box>
        <EditProductDiag
          value={editProduct}
          setValue={seteditProduct}
          open={open}
          onClose={handleCloseDiag}
          submit={handleSubmitEdit}
          onDelete={handleDelete}
          openBackdrop={openEditBackdrop}
          img={editImg}
          imgList={editImgList}
          onChangeImg={onChangeImg}
          onChangeImgList={onChangeImgList}
          onEditImgList={onEditImgList}
        />
        <Alert
          open={openDelAlert}
          title={"ລຶບສິນຄ້າ"}
          content="ທ່ານຕ້ອງການຢາກລຶບສິນຄ້ານີ້ແທ້ບໍ່?"
          ok={"ຕົກລົງ"}
          cancel={"ຍົກເລີກ"}
          handleCancel={handleCancelDel}
          handleSubmit={handleConfirmDel}
          openBackdrop={openDelBackdrop}
        />
        <Alert
          open={openUnpublishAlert}
          title={"ງົດວາງຂາຍສິນຄ້າ"}
          content="ທ່ານຕ້ອງການຢາກງົດວາງຂາຍສິນຄ້ານີ້ຫຼືບໍ່?"
          ok={"ຕົກລົງ"}
          cancel={"ຍົກເລີກ"}
          handleCancel={handleCancelUnpublish}
          handleSubmit={handleConfirmUnpublishProduct}
        />
        <Alert
          open={openPublishAlert}
          title={"ວາງຂາຍສິນຄ້າ"}
          content="ທ່ານຕ້ອງການຢາກວາງຂາຍສິນຄ້ານີ້ຫຼືບໍ່?"
          ok={"ຕົກລົງ"}
          cancel={"ຍົກເລີກ"}
          handleCancel={handleCancelPublish}
          handleSubmit={handleConfirmPublishProduct}
        />

      </Box>
    )
  } else {
    return (
      <Box>
        <AuthenWarning
          t={props.t}
          content="ໜ້ານີ້ສຳລັບແອັດມິນ"
          title="ແຈ້ງເຕືອນ"
          handleClick={handleClick}
          open={true}
        />
      </Box>
    )
  }
}
