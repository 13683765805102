import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    center: {
        display: 'flex',
        //textAlign: 'center',
        color: theme.palette.background.default,
        justifyContent: 'center'
    },
    bgcolor: {
        //backgroundColor: theme.palette.secondary.light,
        padding: '1%',
        display: "flex",
        flexDirection: "column"
    },
    bgTitleContent: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.light,
        //backgroundColor: 'red',
    },
    content: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export default function PopupWarning(props) {
    const classes = useStyles();

    return (
        <div>
            <Dialog open={props.open} aria-labelledby="form-dialog-title" scroll="body" maxWidth='xs' fullWidth >
                <DialogTitle>
                    <div>
                        {props.title}
                    </div>
                </DialogTitle>
                <DialogContent className={classes.bgcolor}>
                    <div style={{ textAlign: 'center' }}>
                    {props.content}
                    </div>
                </DialogContent>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '2%' }}>
                    <Button variant="contained" onClick={props.handleClick} style={{ textTransform: 'none' }} >
                        ຕົກລົງ
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}
